// Responsive Menu
jQuery(document).ready(function() {
	jQuery('#nav-menu').sidr({
		name: 'nav-mobile',
		side: 'right'
	});
});

jQuery(document).ready(function() {	
	jQuery('.sidr a').click(function() {
		jQuery.sidr('close', 'nav-mobile');
	});
});


// Jump Links Scroll
if ( jQuery(window).width() > 767) {
	//Javascript for large screens
	jQuery(document).ready(function() {
		function getTargetTop(elem){
			var id = elem.attr("href");
			var offset = 80;
			return jQuery(id).offset().top - offset;
		}
		jQuery('a[href^="#"]').click(function(event) {
			var target = getTargetTop(jQuery(this));
			jQuery('html, body').animate({scrollTop:target}, 500);
			event.preventDefault();
		});
		var sections = jQuery('li.nav-scroll a[href^="#"]');
		function checkSectionSelected(scrolledTo){
			var threshold = 400;
			var i;
			for (i = 0; i < sections.length; i++) {
				var section = jQuery(sections[i]);
				var target = getTargetTop(section);
				if (scrolledTo > target - threshold && scrolledTo < target + threshold) {
					sections.removeClass("active");
					section.addClass("active");
				}
			};
		}
		checkSectionSelected(jQuery(window).scrollTop());
		jQuery(window).scroll(function(e){checkSectionSelected(jQuery(window).scrollTop())});
	});
}
else {
	//Javascript for small screens
	jQuery(document).ready(function() {
		function getTargetTop(elem){
			var id = elem.attr("href");
			var offset = 80;
			return jQuery(id).offset().top - offset;
		}
		jQuery('a[href^="#"]').click(function(event) {
			var target = getTargetTop(jQuery(this));
			jQuery('html, body').animate({scrollTop:target}, 0);
			event.preventDefault();
		});
		var sections = jQuery('li.nav-scroll a[href^="#"]');
		function checkSectionSelected(scrolledTo){
			var threshold = 200;
			var i;
			for (i = 0; i < sections.length; i++) {
				var section = jQuery(sections[i]);
				var target = getTargetTop(section);
				if (scrolledTo > target - threshold && scrolledTo < target + threshold) {
					sections.removeClass("active");
					section.addClass("active");
				}
			};
		}
		checkSectionSelected(jQuery(window).scrollTop());
		jQuery(window).scroll(function(e){checkSectionSelected(jQuery(window).scrollTop())});
	});
}

// Scroll Background Color
jQuery(document).ready(function () {
	jQuery(window).scroll(function () {
		if (jQuery(document).scrollTop() > 200) {jQuery(".nav-wrapper").addClass("scrolled");} else {jQuery(".nav-wrapper").removeClass("scrolled");}
	});
});


// Expanders
jQuery(function() {
	
	jQuery('.expander').each(function(){
		
		jQuery("div.expand", jQuery(this)).toggler();
		
		jQuery(this).expandAll({
			trigger: "div.expand", 
			ref: "div.expand", 
			showMethod: "slideDown", 
			hideMethod: "slideUp",
			expTxt: "Expand All", 
			cllpsTxt: "Collapse All"
		});
		
	});

});
